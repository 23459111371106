import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFView SnapToNearestInDoc:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl openUniversalDocumentWithConversion:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl documentConversion]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl SnapToNearestInDoc:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlStreamingEventNotification</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlPageNumberUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlStreamingEventTypeUserInfoKey</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeImageStamp</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameImageStamp</div>
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationIdentifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyle.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotStyleKeySnapping</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleDelegate annotStyle:snappingToggled:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyle.snappingEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyleViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleViewController initWithToolManager:annotStyle:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTImageStampCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTImageStampCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTReflowViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTReflowViewControllerScrollingDirection</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewControllerScrollingDirectionHorizontal</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewControllerScrollingDirectionVertical</div>
<div class="difference"><span class="status added">Added</span> -[PTReflowViewController initWithPDFViewCtrl:scrollingDirection:]</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.scrollingDirection</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolSwitching.toolManager</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTToolEvents,PTToolOptionsDelegate&gt; toolManager</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) PTToolManager *toolManager</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolOptionsDelegate snapToDocumentGeometry]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolOptionsDelegate setSnapToDocumentGeometry:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.imageStampAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.rulerAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.perimeterAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.areaAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.snapToDocumentGeometryEnabled</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}